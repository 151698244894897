<template>
  <UBanner
    v-if="!loading"
  >
    <article
      class="box"
    >
      <h1 class="page-title">
        {{ localDocumentTitle }}
      </h1>

      <div v-if="surveys.length">
        <VRow>
          <VCol
            v-for="survey in surveys"
            :key="survey.id"
            cols="12"
            sm="12"
            md="6"
            lg="4"
            xl="3"
          >
            <SurveyCard
              :to="getTo(survey)"
              :survey="survey"
              advanced
            />
          </VCol>
        </VRow>
      </div>

      <UPlaceholder v-else />
    </article>
  </UBanner>

  <VProgressCircular
    v-else
    :size="50"
    color="primary"
    indeterminate
    class="app-loader"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UBanner from '@components/UBanner.vue'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'
import UPlaceholder from '@components/UPlaceholder.vue'
import documentTitle from '@/mixins/documentTitle'

import SurveyCard from '@components/SurveyCard.vue'

export default {
  name: 'TheSurveys',

  components: {
    UPlaceholder,
    UBanner,
    SurveyCard
  },

  mixins: [documentTitle],

  data () {
    return {
      loading: true,
      documentTitle: '$sdo.lc_menu_surveys.other',
    }
  },

  computed: {
    ...mapGetters({
      surveys: getters.SURVEYS
    }),
  },

  created () {
    this.register()
  },

  methods: {
    ...mapActions({
      getSurveys: actions.GET_SURVEYS
    }),

    async register () {
      try {
        await this.getSurveys()
      } catch (e) {
        //
      } finally {
        this.loading = false
      }
    },

    getTo (survey) {
      if (survey.is_started && survey.status?.code !== 'pending' && (survey.is_active || survey.is_completed)) {
        return { name: 'survey', params: { surveyID: survey.id } }
      }

      return ''
    },
  }
}
</script>
